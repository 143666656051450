import BasePlugin from '../BasePlugin'

export default class RegistrationRouteMap extends BasePlugin {
  async execute () {
    let payload = {
      'recordId': this.context.getModel().id,
      'registryId': 1882
    }
    this.send(payload)
    this.context.getCard().$emit('cancelChanges')
  }
}
